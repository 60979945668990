<template>
    <Card>
        <div slot="title">当前正在编辑用户组【{{ formData.groupname || '-' }}】的权限分配，必须选择相应的权限，用户组下的用户才可正常登录系统。</div>
        <Tree ref="tree" :data="dataPermission" show-checkbox multiple expand-node :check-strictly="false"></Tree>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">取消</i-button>
            <i-button class="ml_50" type="success" @click="onConfirmEdit">保存</i-button>
        </div>
    </Card>
</template>
<script>
import { mapActions } from 'vuex';
import { pageMenus } from '@/router/routes';
import { reqSaveGroupPower } from '@/lib/request/auth';
import { consoleJson, isNotNullObject } from '@/lib/util';
import { permissionDef } from '@/lib/permissionDef';

export default {
    name: 'sysManagerPermission',
    data() {
        return {
            dataPermission: [],
            formData: {
                id: null,
                groupname: null,
                flagarr: null,
            },
        };
    },
    mounted() {
        this.routeParamsInjectFormData();
        this.getDataPermission();
    },
    methods: {
        ...mapActions(['setRoutes', 'setPerms']),
        getDataPermission() {
            let dataPermission = [];

            //当前权限
            let permissions = this.formData.flagarr || [];
            // console.log(permissions);

            //权限数据，只取一级 二级数据
            pageMenus.forEach(info => {
                let meta = info.meta || {};
                let title = meta.title || null;
                let permission = meta.permission || null;
                if (title && permission) {
                    let hasChildren = (info.children || []).length > 0;
                    let itemInfo = {
                        title,
                        permission,
                        checked: hasChildren ? false : permissions.includes(permission),
                        expand: true,
                    };
                    if (hasChildren) {
                        itemInfo.children = [];
                        info.children.forEach(subInfo => {
                            let subMeta = subInfo.meta || {};
                            let title = subMeta.title || null;
                            let permission = subMeta.permission || null;

                            let btnArrObj = (Object.values(permissionDef).find(info => info.value == permission) || {}).children || {};
                            let hasBtnPermission = isNotNullObject(btnArrObj);
                            let subItemInfo = {
                                title,
                                permission,
                                checked: hasBtnPermission ? false : permissions.includes(permission),
                            };

                            if (hasBtnPermission) {
                                subItemInfo.children = [];
                                for (let pk in btnArrObj) {
                                    subItemInfo.children.push({
                                        title: btnArrObj[pk],
                                        permission: pk,
                                        checked: permissions.includes(pk),
                                    });
                                }
                            }
                            itemInfo.children.push(subItemInfo);
                            // console.log('permissions.includes(permission)', permissions.includes(permission), permission);
                        });
                    }
                    dataPermission.push(itemInfo);
                }
            });
            this.dataPermission = dataPermission;
            // consoleJson(dataPermission);
        },
        //取消
        onCloseEdit() {
            this.$router.back();
        },
        //保存
        onConfirmEdit() {
            let checkeds = this.$refs.tree.getCheckedAndIndeterminateNodes();
            let permissions = checkeds.map(info => info.permission);

            // consoleJson(permissions);

            this.showAjaxLoading();
            reqSaveGroupPower({
                id: this.formData.id,
                flagarr: JSON.stringify(permissions),
            })
                .then(res => {
                    this.fadeAlert(res.errmsg);
                    this.onCloseEdit();
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
            // this.setRoutes([]);
            // this.setPerms(permissions);
            // this.goUrl('/');
        },
    },
};
</script>
